import { FASTElement, Observable, attr, css, customElement, html, observable } from "@microsoft/fast-element";

const template = html<ButtonSpinner>/*html*/`
<div class="wrapper">
    <slot name="button"></slot>
    <slot name="spinner"><span class="loader ${x => x.isLoading}"></span></slot>
</div>

`	

const styles = css`
.wrapper {
    position: relative;
    box-sizing: border-box;
}
.loader {
    position: absolute;
    top: calc(50% - 5px);
    left: 50%;
    border: 5px solid #FFF;
    border-bottom-color: #FF3D00;
    border-radius: 50%;
    display: inline-block;
    align-self: center;
    align-content: center;
    
    animation: rotation 1s linear infinite;
}
@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
.hidden {
    display: none;
}
`

@customElement({
    name: 'button-spinner',
    template,
    styles
})
export class ButtonSpinner extends FASTElement {
    // @attr disabled: boolean;
    _disabled = false;
    @attr loading = false;
    get isLoading() {
        return this.loading ? '' : 'hidden';
    }
    get disabled():boolean {
        return this._disabled
    }
    set disabled(value:boolean) {
        // find the element that is occupying the slot and set the disabled attribute on it
        const el = this.querySelector('[slot="button"]');
        if (el) {
            if (value == true) {
                el.setAttribute('disabled', '');
            } else {
                el.removeAttribute('disabled');
            }
        }
        this._disabled = value;
        if (value == true) {
            this.setAttribute('disabled', '');
        } else {
            this.removeAttribute('disabled');
        }
    }
    constructor() {
        super();
    }
    async connectedCallback(): Promise<void> {
        super.connectedCallback();

        // find the element that is occupying the slot and set the disabled attribute on it
        const el = this.querySelector('[slot="button"]');
        if (el) {
            if (this.disabled == true) {
                el.setAttribute('disabled', '');
            } else {
                el.removeAttribute('disabled');
            }
        }
    }
}